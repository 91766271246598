const messages = {
    generalData: 'Información General',
    schedule: 'Horarios',
    salary: 'Salario',
    workHours: 'Horas de Trabajo',
    commissions: 'Comisiones',
    history: 'Historia',
    contactInfo: 'Información de Contacto',
    employees: 'Empleados',
    employeesList: 'Todos los Empleados Registrados',
    socialSecNumber: 'No. Seguridad Social',
    office: 'Oficina',
    occupation: 'Ocupación',
    dateOfBirth: 'Fecha de Nacimiento',
    hireDate: 'Fecha de Contratación',
    dismissalDate: 'Fecha de Baja',
    generalDataHelpText: 'Información General del Empleado',
    company: 'Compañía',
    companyTaxNumber: 'No. Fiscal de Compañía',
    contactInfoHelpText: 'Varias maneras de contactar al empleado',
    systemAccount: 'Cuenta de Usuario',
    systemAccountHelpText: 'Perfil de usuario asociado a este trabajador en el sistema',
    savingProgress: 'Guardando Progreso',
    savingProgressHelpText: 'Tus datos serán restaurados si regresas en otro momento',
    employeeIsCompanyHelpText: 'Marcar si el empleado se contrata como una compañía',
    itsCompany: 'Es una compañía',
    companyName: 'Nombre de la Compañía',
    companyTin: 'NIT de la Compañía',
    ssn: 'No. de Seguridad Social',
    weeklyHours: 'Horas Semanales',
    otherPaymentPreferences: 'Otras Preferencias Adicionales de Pago',
    methodOfPayments: 'Método de Pago',
    allowOvertimePayments: 'Permitir Pagos de Horas Extra',
    allowOvertimePaymentsHelpText:
        'Marcar si el empleado puede recibir pagos por concepto de horas extras.',
    selectBaseSalary: 'Selecciona el Salario Base',
    hourlySalary: 'Salario por Hora',
    yearlySalary: 'Salario Anual',
    weeklySalary: 'Salario Semanal',
    setManually: 'Poner Manualmente',
    computed: 'Calculado',
    weekly: 'Semanal',
    biweekly: 'Bi-Semanal',
    monthly: 'Mensual',
    preferredPaymentCycle: 'Ciclo de Pago Preferido',
    preferredPaymentCycleHelpText: 'Con qué Frecuencia Desea el Empleado que le Paguen',
    newEmployee: 'Nuevo Empleado',
    registerEmployee: 'Registrar Empleado',
    selectEmployeeWorkingDays: 'Seleccione los Días Laborables del Empleado',
    workingHours: 'Horario de Trabajo',
    hoursWorked: 'Horas Trabajadas',
    totalDailyHours: 'Total de Horas Diarias',
    totalHours: 'Horas Totales',
    entryTime: 'Hora de Entrada',
    exitTime: 'Hora de Salida',
    salaryTypes: 'Tipos de Salario',
    salaryTypesHelpText: 'Tipos de Salario Configurado para este Empleado',
    fixedSalary: 'Salario Fijo',
    hourlyWage: 'Sueldo por Hora',
    commissionSalary: 'Sueldo por Comisión',
    individualCommissionSalary: 'Sueldo por Comisión Individual',
    employeePayRate: 'Tasa de Pago de Empleado',
    employeePayRateHelpText: 'Ganancias de este Empleado en un Período de Tiempo',
    currentSchedule: 'Horario Actual',
    thisEmployeeWorksOn: 'Este Empleado Trabaja los',
    noPayrollsForEmployee: 'No hay nóminas anteriores para el empleado',
    noPayrollsForEmployeeHelpText: 'Tan pronto como se genere una, estará disponible aquí',
    noCommissionsConfigForEmployee: 'Sin configuración de comisiones',
    noCommissionsConfigForEmployeeHelpText: 'Edite el empleado si desea configurar sus comisiones',
    noRelatedUserForEmployee: 'Este empleado no tiene cuenta de usuario relacionada',
    noRelatedUserForEmployeeHelpText: 'Un usuario disponible debe existir para esto',
    selectUserAccountForEmployee: 'Seleccione una cuenta de usuario para vincularla',
    selectUserAccountForEmployeeHelpText:
        'El empleado podrá autenticarse en el sistema a través de este usuario',
    otherUserActionsHelpText: 'Algunas acciones de la cuenta vinculada',
    employeeLinkedAccountNotFound: 'No se encontraron cuentas de usuarios',
    employeeLinkedAccountNotFoundHelpText:
        'Por favor actualice los valores por los que está filtrando o contacte a su administrador.',
    confirmLinkUserAccount: 'Vincular cuenta de usuario',
    linkUserAccountHelpText:
        'El empleado podrá autenticarse en el sistema a través de este usuario. ¿Continuar?',
    unlinkUserAccount: 'Desvincular cuenta de usuario',
    unlinkUserAccountHelpText:
        'Se desvinculará la cuenta de usuario del empleado, pero no se borrará. ¿Continuar?',
    resendPasswordReset: 'Enviar Reset de Contraseña',
    confirmUnlinkUserAccount: 'Desvincular Usuario',
    otherActions: 'Otras Acciones',
    anyEmployee: 'Cualquier Empleado',
    addEmployee: 'Añadir Empleado',
    reasonForChange: 'Motivo del Cambio',
    workingHoursEditedSuccessfully: 'Horas trabajadas editadas satisfactoriamente',
    workingHoursCreatedSuccessfully: 'Horario registrado satisfactoriamente',
    allowOverNight: 'Permitir Trabajar Durante la Noche',
    allowOverNightHelpText: 'Marcar si el empleado puede recibir pagos por concepto de Overnight.',
    selectOccupation: 'Seleccione una Ocupación',
    unauthorizedOccupationsTemplatesList:
        'Usted no tiene autorización para ver el listado de plantillas de ocupaciones',
    unauthorizedAddOccupationTemplate:
        'Usted no tiene autorización para adicionar una plantilla de ocupación',
    unauthorizedEditOccupationTemplate:
        'Usted no tiene autorización para editar una plantilla de ocupación',
    unauthorizedEmployeesStatistics:
        'Usted no tiene autorización para ver estadísticas de empleados',
    unauthorizedAddCommissionRule:
        'Usted no tiene autorización para adicionar una regla de comisión',
    unauthorizedCommissionsRulesList:
        'Usted no tiene autorización para ver el listado de reglas de comisiones',
    nonOccupationTemplate:
        'Esta ocupación no tiene plantilla definida. Deberá completar los datos manualmente.',
    unauthorizedEmployeesDetails: 'Usted no tiene autorización para ver los detalles del empleado.',
    unauthorizedEmployeesWorkingHours:
        'Usted no tiene autorización para ver las horas trabajadas del empleado.',
    overtimeEditedSuccessfully: 'Configuración de horas extras editada exitosamente',
    overtimeAddedSuccessfully: 'Configuración de horas extras adicionada exitosamente',
    noBonusesForEmployee: 'No hay bonos para esta nómina',
    noBonusesForEmployeeHelpText:
        'Usa los bonos para recompensar logros u otras metas especiales en el período de pago',
    addBonus: 'Añadir Bono',
    addDeduction: 'Añadir Deducción',
    bonus: 'Bono',
    deduction: 'Deducción',
    reason: 'Razón',
    noDeductionsForEmployee: 'Sin deducciones para esta nómina',
    noDeductionsForEmployeeHelpText: 'Incluya impuestos y otros beneficios como seguros de salud',
    included: 'Incluída',
    allowToSkipLunchBreakPunch: 'Permitir Saltarse el Almuerzo',
    currentScheduleHelpText: 'Datos de la Jornada Laboral del Empleado',
    currentScheduleTitle: 'Horario de Trabajo Actual del Empleado',
    editCurrentRegister: 'Editar Registro Actual',
    editCurrentRegisterHelpText: 'Editar registro actual',
    searchPaymentPeriods: 'Buscar Período de Pago',
    allPeriods: 'Todos los Períodos',
    noPaymentPeriods: 'No hay Períodos de Pago para estos Criterios',
    noPaymentPeriodsHelpText: 'Adicione uno Nuevo para Comenzar',
    selectPayrolls: 'Seleccionar Nóminas',
    overtime: 'Horas Extras',
    paymentDay: 'Día del Pago',
    paymentPeriod: 'Período de Pago',
    registeredHoursDoNotMatch: 'Horas registradas no se corresponden',
    registeredHoursDoNotMatchHelpText:
        'Esto significa que { employeeName } tiene entradas para este período ' +
        ' que no están reflejadas en este borrador de nómina. Probablemente porque cambiaron ' +
        ' luego de creado el borrador',
    updateUnregisteredHours: '¿Actualizar el borrador para incluir las horas?',
    updateNow: 'Actualizar ahora',
    noLeaveAsIs: 'Dejar como está',
    readyToPrint: '¿Listo para imprimir?',
    checkPrintingDate: 'Fecha de impresión del cheque',
    includeThisPayrollForReview: 'Incluir esta nómina en la revisión',
    includeThisPayrollForReviewHelpText:
        'Esto marcará esta nónima como lista para pagarse. Esta acción puede revertirse luego si hace falta.',
    editEmployee: 'Editar Empleado',
    selectTime: 'Seleccionar Horario',
    sameSchedule: 'Establecer el mismo horario para todos los días.',
    setSchedule: 'Configurar Horario',
    schedulesSetUpSuccessfully: 'Horarios configurados exitosamente',
    currentSchedulesByDay: 'Horarios Actuales Por Día',
    scheduledEntryTime: 'Hora de Entrada Configurada',
    scheduledIn: 'Entrada Programada',
    scheduledExitTime: 'Hora de Salida Configurada',
    scheduledOut: 'Salida Programada',
    timeTable: 'Horario',
    scheduledTimeFor: 'Horario Programado Para ',
    printCheck: 'Imprimir Cheque',
    initialCheckNumber: 'Número del Cheque Inicial',
    finalCheckNumber: 'Número del Cheque Final',
    titleErrorNumberChecks: 'Error in the Number of Checks',
    messageErrorNumberChecks:
        'The number of checks does not match the number of payments to be made.',
    scheduleForCurrentRecord: 'Horario Para el Registro Actual',
    openNewPaymentPeriod: 'Abrir Nuevo Período de Pago',
    draftCreatedOn: 'Borrador Creado En',
    currentDraft: 'Borrador Actual',
    editDraft: 'Editar Borrador',
    completed: 'Terminado',
    department: 'Departamento',
    paidByStructure: 'Reporte de Monto Pagado En Nómina Por Estructura',
    paidByEmployees: 'Reporte de Monto Pagado En Nómina Por Empleados',
    workedHoursByEmployees: 'Reporte de Horas Trabajadas En Nómina Por Empleados',
    paidByStructureExplanatoryText:
        'Genera un informe de cuánto dinero se pagó en nómina por estructura',
    paidByEmployeesExplanatoryText:
        'Genera un informe de cuánto dinero se pagó en nómina por empleados',
    workedHoursByEmployeesExplanatoryText:
        'Genera un informe de horas trabajadas en nómina por empleados',
    workedHours: 'Horas Trabajadas',
    clickShowSsn: 'Click aquí para mostrar el SSN.',
    clockInOptions: 'Opciones de registro',
    mobileClockIn: 'Reloj móvil en',
    biometricClockIn: 'Reloj biométrico en',
    unauthorizedEmployeesGeneralData:
        'Usted no tiene autorización para ver los detalles del empleado.',
    unauthorizedEmployeesSalaryData:
        'Usted no tiene autorización para ver los datos salariales del empleado.',
    unauthorizedEmployeesWorkHours:
        'Usted no tiene autorización para ver las horas trabajadas del empleado.',
    unauthorizedEmployeesCommissions:
        'Usted no tiene autorización para ver las comisiones del empleado.',
    unauthorizedEmployeesPayrollHistory:
        'Usted no tiene autorización para ver el historial de nómina del empleado.',
    unauthorizedEmployeesEmployeeUserAccount:
        'Usted no tiene autorización para ver la cuenta de usuario del empleado.',
    defaultToOvertimeOnClockIn: 'Overtime por defecto al ponchar',
    defaultToOvertimeOnClockInHelpText: 'Todos los turnos permiten overtime al crearse',
    salaryHistorical: 'Histórico de salario',
};

export default messages;
