import { Notifications } from '@/composables/notification';
import _ from 'lodash';
import { ref } from 'vue';
import { ErrorResult } from '@/composables/common';
const responseErrors = ref();
const setFormErrors = (setErrors: ((fields: any) => void) | undefined, formObject: any) => {
    if (
        typeof setErrors != 'undefined' &&
        !_.isEmpty(formObject) &&
        responseErrors.value instanceof Object &&
        'data' in responseErrors.value
    ) {
        const formErrors = {};
        Object.keys(formObject).forEach((formKey) => {
            Object.keys(responseErrors.value.data).forEach((errorKey) => {
                if (formKey.toLowerCase() === errorKey.replace(/^\$\./, '').toLowerCase()) {
                    formErrors[formKey] = responseErrors.value.data[errorKey];
                }
            });
        });
        if (!_.isEmpty(formErrors)) {
            setErrors(formErrors);
        }
    }
};
export const defaultReject = (response: ErrorResult, callback?: (n: any) => void) => {
    response.isSystemException()
        ? Notifications.Error(String(response))
        : Notifications.Warning(String(response));
    if (callback != undefined) callback(response);
};
export async function resolverWrapper(
    axiosRequest: Promise<any>,
    resolve: (n: any) => Promise<void> | void,
    optionals?: {
        reject?: (n: any) => void;
        finally?: () => void;
        setErrors?: (fields: any) => void;
        formObject?: any;
    }
) {
    await axiosRequest
        .then(async (response) => {
            if (response.data.success) {
                await resolve(response.data);
            } else {
                if (typeof optionals?.reject != 'undefined')
                    optionals?.reject(new ErrorResult(response.data.message, 200));
                else defaultReject(new ErrorResult(response.data.message, 200));
            }
        })
        .catch((e) => {
            responseErrors.value = e;
            console.log(responseErrors.value);
            setFormErrors(optionals?.setErrors, optionals?.formObject);
            if (typeof optionals?.reject != 'undefined') optionals?.reject(e);
            else defaultReject(e);
        })
        .finally(() => {
            if (typeof optionals?.finally != 'undefined') optionals?.finally();
        });
}
