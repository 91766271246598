import { defineStore } from 'pinia';
import { getImage } from '@/composables';
import { TimerProcess, useMedicalServicesStore } from '@/store/medical.store';
import { AccountApi, UsersApi } from '@/api';
import {
    ApplicationUserDto,
    ChangePasswordCommand,
    ForgotPasswordCommand,
    LoginCommand,
    RefreshTokenCommand,
    ResetPasswordCommand,
    SendTwoFactorCodeCommand,
    TokenDto,
    UserProfileDto,
    VerifyResetPasswordTokenCommand,
    VerifyTwoFactorCodeCommand,
    ImpersonateUserCommand,
} from '@/models';
import { UpdateProfileUserRequest } from '@/api/identity/request/users.request';
import { Guid } from 'guid-typescript';
import router from '@/routes/index';
import dayjs from 'dayjs';

const api = new AccountApi();
const userApi = new UsersApi();
export const useIdentityStore = defineStore({
    id: 'identity',
    persist: true,
    state: () => ({
        data: null as TokenDto | null | undefined,
        returnUrl: null as any | null,
        selectedProvider: null as string | null,
        cache: new Map<string, ApplicationUserDto>(),
    }),
    getters: {
        avatar: (state) =>
            state?.data?.profile?.avatar ? getImage(state?.data?.profile?.avatar) : undefined,

        token: (state) => () => state.data?.token,

        tokenExpired: (state) => () =>
            state.data == null ||
            dayjs(state.data.refreshTokenExpiryTime).subtract(121, 'minutes').isBefore(dayjs()),

        refreshTokenExpired: (state) => () =>
            state.data == null || dayjs(state.data.refreshTokenExpiryTime).isBefore(dayjs()),

        userFullName: (state) =>
            `${state.data?.profile?.name ?? 'unknown'} ${
                state.data?.profile?.surName ?? ' stranger'
            }`,
    },
    actions: {
        async waitForToken(): Promise<string> {
            while (this.tokenExpired()) await new Promise((_) => setTimeout(_, 5000));
            return this.token() ?? '';
        },

        setReturnUrl(returnUrl) {
            this.returnUrl = returnUrl;
        },

        authorized(
            allOf: string[] | undefined,
            anyOf: string[] | undefined,
            notAnyOf: string[] | undefined = undefined
        ): boolean {
            let isAllOf = false;
            let isAnyOf = false;
            let isNotAnyOf = false;

            if (!allOf) isAllOf = true;
            else
                isAllOf = allOf.every((val) => {
                    const includes = this.data?.permissions?.includes(val);
                    if (!includes)
                        console.warn(
                            'FrontEnd Missing Permission: ' + val + ' From AllOf: ' + allOf
                        );
                    return includes;
                });

            if (!anyOf || anyOf.length == 0) isAnyOf = true;
            else {
                isAnyOf = anyOf.some((val) => this.data?.permissions?.includes(val));
                if (!isAnyOf) console.warn('FrontEnd Missing Permission From AnyOf: ' + anyOf);
            }
            if (!notAnyOf || notAnyOf.length == 0) isNotAnyOf = true;
            else {
                isNotAnyOf = !notAnyOf.some((val) => this.data?.permissions?.includes(val));
                if (!notAnyOf) console.warn('FrontEnd Missing Permission From AnyOf: ' + anyOf);
            }
            return isAllOf && isAnyOf && isNotAnyOf;
        },

        // Login
        async login(username, password) {
            return await api
                .accountLogin({ username, password } as LoginCommand)
                .then(async (response) => {
                    if (
                        response.data.success &&
                        response.data.data &&
                        response.data.data.token != null
                    ) {
                        this.data = response.data.data;
                        if (response.data.data.requirePasswordUpdate) {
                            router.push({ name: 'UpdatePassword' });
                            this.returnUrl = router.currentRoute;
                        } else {
                            router.push(this.returnUrl || '/');
                            this.returnUrl = null;
                        }
                    } else if (
                        response.data.success &&
                        response.data.data &&
                        response.data.data.twoFactorRequired
                    ) {
                        router.push('/2fa');
                    } else {
                        return Promise.reject(response.data.message);
                    }
                });
        },

        async impersonateAccount(userId) {
            return await api
                .accountImpersonateUser({ userId } as ImpersonateUserCommand)
                .then(async (response) => {
                    if (
                        response.data.success &&
                        response.data.data &&
                        response.data.data.token != null
                    ) {
                        this.data = null;
                        if (
                            window.sessionStorage.getItem('medicalServices') ||
                            window.localStorage.getItem('medicalServices')
                        ) {
                            useMedicalServicesStore().resetTimer(TimerProcess.MASSAGE);
                            useMedicalServicesStore().resetTimer(TimerProcess.NONSURGICAL);
                        }
                        window.localStorage.removeItem('medicalServices');
                        window.localStorage.removeItem('humanResources');
                        this.data = response.data.data;
                        await router.push('/');
                        location.reload();
                        this.returnUrl = null;
                    } else {
                        return Promise.reject(response.data.message);
                    }
                });
        },

        async logout() {
            this.data = null;
            if (
                window.sessionStorage.getItem('medicalServices') ||
                window.localStorage.getItem('medicalServices')
            ) {
                useMedicalServicesStore().resetTimer(TimerProcess.MASSAGE);
                useMedicalServicesStore().resetTimer(TimerProcess.NONSURGICAL);
            }
            window.localStorage.removeItem('medicalServices');
            window.localStorage.removeItem('humanResources');
            if (router.currentRoute.value.name != 'Login') router.push('/login');
        },

        async refresh() {
            const token = this.data?.token;
            const refreshToken = this.data?.refreshToken;
            this.data = null; // So the expired token is not passed to the Refresh endpoint.
            return await api
                .accountRefresh({ token, refreshToken } as RefreshTokenCommand)
                .then(async (response) => {
                    if (response.data.success) {
                        this.data = response.data.data;
                        return Promise.resolve();
                    } else {
                        await this.logout();
                        return Promise.reject(response.data.message);
                    }
                })
                .catch(async (error) => {
                    await this.logout();
                    return Promise.reject(error);
                });
        },

        // TwoFactor
        async getTwoFactorProviders() {
            this.selectedProvider = null;
            return await api
                .accountGetTwoFactorProviders()
                .then((response) => {
                    if (response.data.success) {
                        return response.data.data;
                    } else {
                        router.push('/login');
                        return Promise.reject(response.data.message);
                    }
                })
                .catch(() => router.push('/login'));
        },

        selectProvider(provider) {
            this.selectedProvider = provider;
        },

        async sendTwoFactorCode() {
            const response = await api.accountSendCode({
                provider: this.selectedProvider,
            } as SendTwoFactorCodeCommand);
            if (!response.data.success) {
                router.push('/login');
                return Promise.reject(response.data.message);
            }
        },

        async verifyTwoFactorCode(provider, code) {
            const response = await api.accountVerifyCode({
                provider,
                code,
            } as VerifyTwoFactorCodeCommand);
            if (response.data.success && response.data.data && response.data.data.token != null) {
                this.data = response.data.data;
                router.push(this.returnUrl || '/');
                this.returnUrl = null;
                this.selectedProvider = null;
            } else {
                return Promise.reject(response.data.message);
            }
        },

        // Password
        async forgotPassword(email: string) {
            return await api.accountForgotPassword(
                { email } as ForgotPasswordCommand,
                String(Guid.create())
            );
        },

        async verifyResetPasswordToken(email: string, code: string) {
            return await api.accountVerifyResetPasswordToken(
                {
                    email,
                    code,
                } as VerifyResetPasswordTokenCommand,
                String(Guid.create())
            );
        },

        async resetPassword(
            email: string,
            code: string,
            password: string,
            passwordConfirm: string,
            xRequestId?: string
        ) {
            const response = await api.accountResetPassword(
                {
                    email,
                    code,
                    password,
                    passwordConfirm,
                } as ResetPasswordCommand,
                xRequestId
            );
            if (!response.data.success) {
                return Promise.reject(response.data.message);
            } else {
                router.push('/login');
                return response;
            }
        },

        async changePassword(
            currentPassword: string,
            newPassword: string,
            confirmPassword: string,
            xRequestid?: string
        ) {
            return await api.accountChangePassword(
                {
                    currentPassword,
                    newPassword,
                    confirmPassword,
                } as ChangePasswordCommand,
                xRequestid
            );
        },

        async updateUserProfile(request: UpdateProfileUserRequest) {
            return api.accountUpdateProfile(
                request.name,
                request.surName,
                request.phoneNumber,
                request.birthDay.toString(),
                request.avatar,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        },

        setProfile(request: UserProfileDto) {
            const newData = {} as TokenDto;
            Object.entries(this.data as object).forEach((e) => {
                const [key, val] = e;
                if (key === 'profile') {
                    newData[key] = request;
                } else {
                    newData[key] = val;
                }
            });
            this.data = newData;
        },

        async getUserCached(id: string): Promise<ApplicationUserDto | undefined> {
            if (this.cache instanceof Map) {
                if (this.cache.has(id)) {
                    return this.cache.get(id);
                }
            } else {
                this.cache = new Map<string, ApplicationUserDto>();
            }

            try {
                const response = await userApi.usersGetApplicationUser(id);
                if (response.data.success && response.data.data) {
                    this.cache.set(id, response.data.data);
                    return response.data.data;
                }
            } catch (e) {
                console.log(String(e));
            }
        },

        async updateCulture(userId: string, cultureCode: string, cultureShortCode) {
            (this.data as TokenDto).cultureCode = cultureCode;
            (this.data as TokenDto).cultureShortCode = cultureShortCode;
            return await api.accountUpdateCulture(
                { userId, cultureCode, cultureShortCode },
                String(Guid.create())
            );
        },
    },
});
