import { RouterView } from 'vue-router';
const EditCompanyLegalData = () => import('@/pages/accounting/company/EditCompanyLegalData.vue');
const AccountingBalances = () => import('@/pages/accounting/balances/BalanceAdjustments.vue');
const AccountingPatients = () => import('@/pages/accounting/AccountingPatients.vue');
const PaymentsTypes = () => import('@/pages/accounting/payments/PaymentsTypes.vue');
const PatientInvoices = () => import('@/pages/accounting/invoices/PatientInvoices.vue');
const AccountingCompensations = () =>
    import('@/pages/accounting/refunds/AccountingCompensations.vue');
const ExternalProviders = () =>
    import('@/pages/accounting/externalProviders/ExternalProviders.vue');
const AllAppointments = () => import('@/pages/frontdesk/AllAppointments.vue');
const LockedPatients = () =>
    import('@/pages/accounting/lockedPatient/chargebacks/LockedPatients.vue');
const ExternalProvidersCategories = () =>
    import('@/pages/accounting/externalProviders/ExternalProvidersCategories.vue');
const AccountingReports = () => import('@/pages/accounting/reports/AccountingReports.vue');
const QuoteLogs = () => import('@/pages/accounting/QuoteLogs.vue');
const AllQuoteTransfers = () => import('@/pages/sales/quoteTransfers/AllQuoteTransfers.vue');
const AllTreatmentTransfers = () =>
    import('@/pages/frontdesk/treatmentTransfers/AllTreatmentTransfers.vue');
const CheckOutSurgeryAppointment = () =>
    import('@/pages/medicalServices/surgeries/checkOut/CheckOutSurgeryAppointment.vue');
const accountingRoutes = [
    {
        path: 'accounting',
        name: 'accounting',
        redirect: (to) => {
            return { path: '/accounting/providers' };
        },
        component: RouterView,
        meta: {
            title: 'Accounting',
            public: false,
            icon: 'CurrencyDollarIcon',
        },
        children: [
            {
                path: 'providers',
                name: 'AccountingExternalProviders',
                component: ExternalProviders,
                meta: {
                    menu: true,
                    title: 'ExternalProviders',
                    permissionsAllOf: [
                        'Permissions.Accounting.ExternalProvider.Search',
                        'Permissions.Accounting.ExternalProvider.Detail',
                    ],
                },
            },
            {
                path: 'external-providers-categories',
                name: 'ExternalProvidersCategories',
                component: ExternalProvidersCategories,
                meta: {
                    menu: true,
                    title: 'ExternalProvidersCategories',
                    permissionsAnyOf: ['Permissions.Accounting.ExternalProviderCategory.Search'],
                },
            },
            {
                path: 'types-payments',
                name: 'AccountingPaymentsTypes',
                component: PaymentsTypes,
                meta: {
                    menu: true,
                    title: 'Payments Types',
                    permissionsAnyOf: ['Permissions.Accounting.PaymentType.Search'],
                },
            },
            {
                path: 'company-legal-data',
                name: 'AccountingEditCompanyLegalData',
                component: EditCompanyLegalData,
                meta: {
                    menu: true,
                    title: 'Company',
                    permissionsAllOf: ['Permissions.Accounting.CompanyLegalData.Update'],
                },
            },
            {
                path: 'balances',
                name: 'AccountingBalances',
                component: AccountingBalances,
                meta: {
                    menu: true,
                    title: 'Balances',
                    permissionsAnyOf: ['Permissions.Accounting.BalanceAdjustments.Search'],
                },
            },
            {
                path: 'patients',
                name: 'AccountingPatients',
                component: AccountingPatients,
                meta: {
                    menu: true,
                    title: 'Patients',
                    permissionsAllOf: [
                        'Permissions.Accounting.LeadProfiles.Search',
                        'Permissions.Accounting.BalanceAdjustments.Search',
                    ],
                },
            },
            {
                path: 'invoice',
                name: 'PatientInvoices',
                component: PatientInvoices,
                meta: {
                    menu: true,
                    title: 'Invoices',
                    permissionsAnyOf: ['Permissions.Accounting.FinishedService.Search'],
                },
            },
            {
                path: 'quoteLogs',
                name: 'QuoteLogs',
                component: QuoteLogs,
                meta: {
                    menu: true,
                    title: 'quoteLogs',
                    permissionsAllOf: ['Permissions.Sales.Quotes.Logs'],
                },
            },
            {
                path: 'refunds',
                name: 'AccountingCompensations',
                component: AccountingCompensations,
                meta: {
                    menu: true,
                    title: 'Refunds',
                    permissionsAnyOf: ['Permissions.Accounting.Refund.Search'],
                },
            },
            {
                path: 'appointments',
                name: 'AllAccountingAppointments',
                component: AllAppointments,
                meta: {
                    menu: true,
                    title: 'All Appointments',
                    permissionsAllOf: ['Permissions.Accounting.Appointments.Search'],
                },
                props: {
                    checkOutRoute: 'AccountingChangeCheckOutSurgeryAppointment',
                },
            },
            {
                path: 'reports',
                name: 'AccountingReports',
                component: AccountingReports,
                meta: {
                    menu: true,
                    title: 'Reports',
                    permissionsAnyOf: ['Permissions.Reports.ExternalProvidersReport.View'],
                },
            },
            {
                path: 'chargebacks',
                name: 'Chargebacks',
                component: LockedPatients,
                meta: {
                    menu: true,
                    title: 'Chargebacks',
                    permissionsAnyOf: ['Permissions.Accounting.LockedPatient.Search'],
                },
            },
            {
                path: 'quote-transfer',
                name: 'QuoteTransfers',
                component: AllQuoteTransfers,
                meta: {
                    menu: true,
                    title: 'QuoteTransfers',
                    permissionsAllOf: ['Permissions.Sales.QuoteTransfers.Search'],
                },
            },
            {
                path: 'treatment-transfer',
                name: 'TreatmentTransfers',
                component: AllTreatmentTransfers,
                meta: {
                    menu: true,
                    title: 'TreatmentTransfers',
                    permissionsAllOf: ['Permissions.FrontDesk.TreatmentTransfers.Search'],
                },
            },
            {
                path: 'appointments/change-check-out/:id/:patientId',
                name: 'AccountingChangeCheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
                props: {
                    editable: true,
                    route: 'AllAccountingAppointments',
                },
            },
        ],
    },
];

export default accountingRoutes;
