const messages = {
    promotions: 'Promociones',
    addPromotion: 'Añadir Promoción',
    addNewSaleProduct: 'Añadir Nuevo Producto de Venta',
    editSaleProduct: 'Editar Producto de Venta',
    addSaleProductPromotion: 'Añadir promoción de productos de venta',
    editSaleProductPromotion: 'Editar promoción de productos de venta',
    confirmDisableProduct: 'Confirmar deshabilitación del producto',
    confirmDeletePromotion: 'Confirmar eliminación de la promoción',
    attributeType: 'Tipo de Atributo',
    date: 'Fecha',
    Type: 'Tipo',
    order: 'Ordenar',
    sort: 'Tipo de Ordenamiento',
    ascending: 'Ascending',
    descending: 'Descending',
    assignedDate: 'Fecha Asignada',
    lastPaymentDate: 'Última Fecha de Pgo',
    nextAppointmentDate: 'Próxima Cita',
    editAppointment: 'Editar Cita',
    createNewAppointment: 'Crear Nueva Cita',
    patientsSurgicalProvider: 'Proveedor quirúrgico del paciente',
    allNonSurgical: 'Todas las No Quirúrgicas',
    patientsAppointments: 'Citas del Paciente',
    allAppointments: 'Todas las Citas',
    assignedDays: 'Días Asignados',
    notInterested: 'No Interesado',
    transfer: 'Transferir',
    myLeads: 'Mis Clientes Potenciales',
    reassignLead: 'Reasignar Lead',
    selectASeller: 'Selecciona un Vendedor',
    uploadChartDocument: 'Cargar Expediente Médico',
    requestPayment: 'Solicitar Pago',
    requestPaymentNoQuoteMessage:
        'Se debe crear una cotización antes de que se puedan solicitar los pagos.',
    paymentLinks: 'Enlaces de Pago',
    paymentLink: 'Enlace de Pago',
    createdAt: 'Creado en',
    linkCopiedToClipboard: 'Enlace copiado al portapapeles.',
    sendPaymentLink: 'Enviar Enlace de Pago',
    link: 'Enlace',
    text: 'Texto',
    providerRequiredForSurgicalProcedures: 'Se requiere proveedor para procedimientos quirúrgicos.',
    selectAProcedure: 'Seleccione un Procedimiento',
    selectAProvider: 'Seleccione un Proveedor',
    quote: 'Cuota',
    upgrade: 'Actualizar',
    priceOverride: 'Anular precio del procedimiento',
    priceTreatmentOverride: 'Anular precio del tratamiento',
    assignmentHistory: 'Historial de Asignaciones',
    recuperate: 'Recuperar',
    recuperation: 'Recuperación',
    recuperable: 'Recuperable',
    return: 'Devolver',
    assignedTimes: 'Número de Tiempo Asignado',
    addToQuote: 'Agregar a la Cuota',
    changesSaved: 'Cambios Guardados.',
    addItem: 'Añadir Artículo',
    addAccessory: 'Añadir Accesorio',
    discounts: 'Descuentos',
    patientsByDebtRange: 'Pacientes por Rango de Deuda',
    debtsPerDay: 'Deudas Por Día',
    salesPerDay: 'Ventas Por Día',
    birthday: 'Birthday',
    transactionCost: 'Costo de Transacción',
    total: 'Total',
    paymentDetails: 'Detalles del Pago',
    last4CardNumber: 'Número de Tarjeta',
    addAssistant: 'Adicionar Asistente',
    assignAssistants: 'Asignar Asistentes',
    assignAssistant: 'Asignar Asistente',
    assistant: 'Asistente',
    pendingBalance: 'Balance Pendiente',
    quoteTotal: 'Cuota Total',
    reassign: 'Reasignar',
    effectiveness: 'Efectividad',
    assignedLeads: 'Leads Asignados',
    leadsAttended: 'Clientes Atendidos',
    newClients: 'Clientes Nuevos',
    clientsWithPhotos: 'Fotos Nuevos',
    walkInClients: 'Clientes sin Cita',
    surgerySold: 'Cirugias Vendidas',
    cosmetologySold: 'Cosmetologia Vendidas',
    balanceSold: 'Balance Vendido',
    totalCharged: 'Total Facturado',
    minimumGreaterThanMaximum:
        "El valor del filtro 'Mínimo de ventas' no puede ser mayor que el valor del filtro 'Máximo de ventas'.",
    inactiveSellers: 'Vendedores Inactivos',
    firstAppSinceAssignmentDate: 'Primera cita desde la asignación',
    criterea: 'Criterio',
    includeCriterea: 'Criterios incluidos',
    excludeCriterea: 'Criterios excluidos',
    assignedToSeller: 'Asignado al vendedor',
    hasQuote: 'Tiene Cuota',
    refund: 'Reembolso',
    updateRefund: 'Actualizar Reembolso',
    clientIdNotEqual: 'Los Id de cliente no son iguales',
    refundDepositTo: 'Reembolsar depósito a',
    cancelDepositTo: 'Cancelar depósito a',
    paymentReference: 'Referencia de pago',
    surgerySales: 'Cirugías vendidas por oficina',
    surgerySalesDescription:
        'Muestra leads asignados, nuevos pacientes vendidos y balace total por oficina',
    cosmetologySalesByOffice: 'Ventas de cosmetología por oficina ',
    cosmetolySalesByOfficeDescription:
        'Obtén información sobre ventas de prodecimientos no quirúrgicos por departamento',
    earningByOfficeReport: 'Ingresos por departamento',
    earningByOfficeReportDescription:
        'Muestra los ingresos totales por oficina. Incluye depósitos, venta de productos y procedimientos no quirúrgicos',
    totalSalesByOffice: 'Total de ventas por oficina',
    totalSalesByOfficeDescription: 'Todas las ventas de la compañía agrupadas en un solo lugar',
    doneSurgeriesByDr: 'Cirugías completadas por doctor',
    doneSurgeriesByDrDescription:
        'Conoce cuántas cirugías un doctor ha completado en un período de tiempo',
    newLeadsByDateRange: 'Nuevos Clientes Potenciales',
    newLeadsByDateRangeDescription:
        'Conoce cuántos Clientes Potenciales fueron creados en un período de tiempo',
    leaveBlankToShowCurrentWeek: 'Dejar vacíos para ver la semana actual',
    marketingUser: 'Comercializador',
    pendingIncomingTransfer: 'Transferencias pendientes entrantes',
    maxAmountToTransferTo: 'Max cantidad a transferir a',
    transferAmount: 'Cantidad a transferir',
    destination: 'Destino',
    transferMovements: 'Transferencias',
    sourceQuote: 'Cuota fuente',
    activeQuotes: 'Cuotas activas',
    sourceItems: 'Artículos fuentes',
    balanceTransferRequest: 'Petición de transferencia de balance a cuota',
    quoteToBalanceTransferRequest: 'Petición de transferencia de cuota a balance',
    transferToTitle: 'Transferencia a {item} de la Cuota #{quote}',
    cancelQuoteTransferRequestMessage:
        'Por favor escriba la causa por la cual quiere cancelar esta petición de transferencia.',
    cancelledBy: 'Cancelado por',
    allQuoteTransfers: 'Todas las transferencias de las cuotas',
    authorizeTransfer: 'Autorizar transferecia #{transfer}',
    doneMassageByMasseuse: 'Masajes completadas por masajista',
    doneMassageByMasseuseDescription:
        'Conoce cuántas cirugías un masajista ha completado en un período de tiempo',
    quoteId: 'ID de la Cuota',
    quoteAuditHistory: 'Historial de auditoria de la cuota',
    source: 'Fuente',
    remaining: 'Restante',
    maxAmount: 'Cantidad máxima',
    financedPrice: 'Financiado (Esta es la cantidad que el cliente esta financiando)',
    financed: 'Financiado',
    addFinancedPayment: 'Agregar pago financiado',
    accredit: 'Acreditar',
    discredit: 'Desacreditar',
    changeDate: 'Cambiar fecha',
    pendingInvoice: 'Factura pendiente',
    linkFee: 'Enlace de tarifa',
    authorizeAccessoryRefund: 'Authorizar rembolso de accessorio',
    transferFromBalance: 'Transferencia desde Balance',
    transferToBalance: 'Transferencia al Balance',
    emptyDepositItems: 'Aún no hay artículos adicionados',
    emptyDepositItemsHelpText: 'Comienza adicionando nuevos artículos al depósito',
    addNewDepositItem: 'Adicionar nuevo artículos',
    addNewDepositItemHelpText: 'Adicionar nuevo artículos al depósito',
    transfers: 'Transferencias',
    co: 'CO',
    coSold: 'CO Sold',
    va: 'VA',
    vaSold: 'VA Sold',
    laser: 'Laser',
    laserSold: 'Laser Sold',
    ra: 'RA',
    cav: 'CAV',
    money: 'Dinero',
    netMoney: 'Dinero Neto',
    walkInCiClients: 'CI por la puerta',
    walkInSoldClients: 'Vendidas',
    clientsWithSoldPhotos: 'Fotos Vendidas',
    clientsWithPhotosAssigned: 'Fotos Asignadas',
    leadAssignedPublicity: 'Publicidad Leads Asignados',
    leadAssignedOthers: ' Otros Leads Asignados',
    leadSoldPublicity: 'Publicidad Leads Vendidos',
    leadSoldOthers: 'Otros Leads Vendidos',
    stay: 'Stay',
    totalSales: 'Total Ventas',
    authorizeProcedureRefund: 'Autorizar rembolso del procedimiento',
    cosmetologyProcedureByDepartment: 'Ventas de procedimientos de cosmetología',
    cosmetologyProcedureByDepartmentDescription:
        'Obtén información sobre ventas de prodecimientos no quirúrgicos',
    invalidInvoice: 'Factura invalida, por favor chequee el total de la factura y el total pagado',
    currentInvoice: 'Factura actual',
    quoteInvoices: 'Facturas de la cuota',
    invalid: 'Invalido',
    LeadsByInactiveSellers: 'Leads de vendedores inactivos',
    reportLoading: 'Cargando datos del informe',
    totalRefunded: 'Total Reembolsado',
    assignSeller: 'Asignar Vendedor',
};
export default messages;
