import { defineStore } from 'pinia';
import { HttpClient } from '@/services/HttpClient';
import { useMainStore } from './main.store';
import { Result } from '@/api/common/response/common.response';
import { PageFilters } from '@/api/common/request/common.request';

import {
    GetFirstContactResponse,
    GetFirstContactsResponse,
} from '@/api/publicity/response/firstContact.response';
import {
    GetCustomerSourceResponse,
    GetCustomerSourcesResponse,
} from '@/api/publicity/response/source.response';
import {
    AddAssetRequest,
    EditAssetRequest,
    GetAssetsRequest,
} from '@/api/publicity/request/assets.request';
import {
    GetAssetConfigResponse,
    GetAssetResponse,
    GetAssetTagsResponse,
} from '@/api/publicity/response/assets.response';
import { AssetConfigDto } from '@/models/asset-config-dto';

import PublicityService from '../services/publicity.services';
import axios from 'axios';

interface PublicityStore {
    addAssetCancelToken: any;
    assetsConfig: AssetConfigDto | null;
    showcaseMode: { active: boolean; deviceId: string | null; date: string | null };
}
export const usePublicityStore = defineStore('publicity', {
    persist: true,
    state: () =>
        ({
            addAssetCancelToken: null,
            assetsConfig: null,
            showcaseMode: {
                active: false,
                deviceId: null as string | null,
                date: null as string | null,
            },
        }) as PublicityStore,
    actions: {
        async getFirstContacts(filters: PageFilters) {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetFirstContactsResponse>('FirstContacts', {
                    params: filters,
                });
            });
        },
        async getFirstContact(id: string) {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetFirstContactResponse>(`FirstContacts/${id}`);
            });
        },

        async getCustomerSources(filters: PageFilters) {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetCustomerSourcesResponse>('CustomerSources', {
                    params: filters,
                });
            });
        },
        async getCustomerSource(id: string) {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetCustomerSourceResponse>(`CustomerSources/${id}`);
            });
        },

        async getAssetTags() {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetAssetTagsResponse>('Assets/AssetTags');
            });
        },
        async getAsset(id: string) {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetAssetResponse>(`Assets/${id}`);
            });
        },
        async getAssetConfig() {
            return await useMainStore().requestWrapper(async () => {
                return HttpClient.get<GetAssetConfigResponse>('Assets/AssetConfig');
            });
        },
        async addAsset(request: AddAssetRequest, xRequestId?: string) {
            const cancelToken = axios.CancelToken;
            this.addAssetCancelToken = cancelToken.source();
            return HttpClient.post<Result>('Assets', useMainStore().getData(request), {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-requestid': xRequestId,
                },
                cancelToken: this.addAssetCancelToken.token,
                timeout: 600000, // 600s = 10m
            });
        },

        cancelAddAsset() {
            this.addAssetCancelToken.cancel('canceled by user');
        },

        async updateAsset(request: EditAssetRequest, xRequestId?: string) {
            const cancelToken = axios.CancelToken;
            this.addAssetCancelToken = cancelToken.source();

            return HttpClient.put<Result>(`Assets/${request.id}`, useMainStore().getData(request), {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-requestid': xRequestId,
                },
                cancelToken: this.addAssetCancelToken.token,
                timeout: 600000, // 600s = 10m
            });
        },
        async getAssets(filters: GetAssetsRequest) {
            return await useMainStore().requestWrapper(async () => {
                return PublicityService.getAssets(filters);
            });
        },

        async activateShowcaseMode(
            active: boolean,
            deviceId: string | null = null,
            date: string | null = null
        ) {
            this.$patch({
                showcaseMode: {
                    active: active,
                    date: date,
                    deviceId: deviceId,
                },
            });
        },
    },
    getters: {
        isShowcaseModeOn: (state) => state.showcaseMode.active,
        showcaseParams: (state) => state.showcaseMode,
    },
});
