import { markRaw } from 'vue';
import { defineStore } from 'pinia';
import { TokenApi } from '@/openapi/token-api';
import { Call, Device } from '@twilio/voice-sdk';

interface DialerState {
    isActiveCall: boolean;
    ready: boolean;
    dialNumber: string;
    isTwilioDeviceReady: boolean;
    isTwilioDeviceConnected: boolean;
    hasIncomingConnection: boolean;
    device: Device | undefined;
    message: string;
    activeConnection: any;
}

export const useDialerStore = defineStore('dialer', {
    state: (): DialerState => ({
        isActiveCall: false,
        ready: false,
        dialNumber: '',
        isTwilioDeviceReady: false,
        isTwilioDeviceConnected: false,
        hasIncomingConnection: false,
        device: undefined,
        message: '',
        activeConnection: undefined,
    }),
    actions: {
        async onReadyClicked() {
            const api = new TokenApi();
            const result = await api.tokenGet().catch(() => undefined);
            if (result && result.data.data) {
                const jwtToken = result.data.data;
                const device = new Device(jwtToken, {
                    closeProtection: true,
                    codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
                });
                device.on('registered', this.onDeviceReady);
                device.on('error', (error) => {
                    this.message = error;
                });
                device.on('connect', (connection) => {
                    if (connection.direction === 'OUTGOING') {
                        this.onTwilioDeviceConnected(connection.message['To']);
                    } else {
                        this.onTwilioDeviceConnected(connection.parameters['From']);
                    }
                    this.activeConnection = markRaw(connection);
                });
                device.on('disconnect', () => {
                    this.isActiveCall = false;
                    this.isTwilioDeviceConnected = false;
                    this.hasIncomingConnection = false;
                    this.message = '';
                    this.dialNumber = '';
                });
                device.on('incoming', (connection) => {
                    this.onIncomingConnection(connection.parameters['From']);
                    this.activeConnection = markRaw(connection);
                });
                device.on('cancel', () => {
                    this.isActiveCall = false;
                    this.hasIncomingConnection = false;
                    this.message = '';
                });
                device.on('offline', async () => {
                    console.log('Token will expire');
                    const api = new TokenApi();
                    const result = await api.tokenGet().catch(() => undefined);
                    if (result && result.data.data) {
                        const jwtToken = result.data.data;
                        this.device?.updateToken(jwtToken);
                    }
                });

                this.device = markRaw(device);
                this.device.register();
                this.ready = true;
            }
        },
        onDeviceReady() {
            this.isTwilioDeviceReady = true;
        },
        onTwilioDeviceConnected(phoneNumber: string) {
            this.dialNumber = phoneNumber;
            this.isActiveCall = true;
            this.isTwilioDeviceConnected = true;
            this.hasIncomingConnection = false;
            let name = null;
            if (name) {
                this.message = 'Calling ' + name;
                // name = null;
                // StateManager.CallingName = null;
            } else {
                this.message = 'Calling ' + phoneNumber;

                /* var response = await HttpClient.GetAsync($"/app/api/MessageCall/{phoneNumber}/Name");
                    if (response.IsSuccessStatusCode && isTwilioDeviceConnected)
                    {
                        this.message = $"{Localizer["Calling"]} {await response.Content.ReadAsStringAsync()}";
                    } */
            }
        },
        onIncomingConnection(phoneNumber: string) {
            this.isActiveCall = true;
            this.dialNumber = phoneNumber;
            this.hasIncomingConnection = true;
        },
        async startCallWrapper(number: string) {
            if (!this.device) return;
            this.isActiveCall = true;
            this.dialNumber = number;
            const call = await this.device.connect({
                params: {
                    To: number,
                },
            });
            this.activeConnection = markRaw(call);
            this.onTwilioDeviceConnected(number);
            // call.on('accept', onAccepted);
            call.on('disconnect', () => {
                this.isActiveCall = false;
                this.isTwilioDeviceConnected = false;
                this.hasIncomingConnection = false;
                this.message = '';
                this.dialNumber = '';
            });
            call.on('cancel', () => {
                this.isActiveCall = false;
                this.hasIncomingConnection = false;
                this.message = '';
            });
        },
        endCallWrapper() {
            this.activeConnection?.disconnect();
            this.isActiveCall = false;
        },
        acceptCallWrapper() {
            this.activeConnection?.accept();
            this.isActiveCall = true;
            this.hasIncomingConnection = false;
            this.isTwilioDeviceConnected = true;
        },
        rejectCallWrapper() {
            this.activeConnection?.reject();
            this.isActiveCall = false;
            this.hasIncomingConnection = false;
            this.dialNumber = '';
        },
        destroy() {
            this.device?.destroy();
        },
    },
});
