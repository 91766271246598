const messages = {
    idPatientName: 'ID/Name',
    date: 'Date',
    adjustmentType: 'Adjustment Type',
    amount: 'Amount',
    notBalanceAdjustment: 'There is not balance adjustments available...',
    motive: 'Motive',
    adjustedBy: 'Adjusted By',
    balanceAdjustments: 'Balance Adjustments',
    balanceAdjustmentsHelpText: 'Balance Adjustments of your Patients',
    Adding: 'Adding',
    Substracting: 'Subtracting',
    updateBalanceTo: 'Update Balance to ',
    updateBalance: 'Update Balance',
    operation: 'Operation',
    subtractionOperations: 'Balance: Subtraction Operations',
    additionOperations: 'Balance: Addition Operations',
    unauthorizedBalanceAdjustments:
        "You don't have authorization to view balance adjustments statistics",
    nonComments: 'There are no comments to show',
    anyPatient: 'Any Patient',
    balanceUpdatedSuccessfully: 'Balance adjustment created successfully',
    motiveDescription: 'Motive Description',
    unauthorizedStatistics: 'Unauthorized Statistics',
    newAdjustment: 'New Adjustment',
    representative: 'Representative',
    logo: 'Logo',
    assignedAsSeller: 'was assigned as seller by',
    paymentsFrom: 'Payments From',
    paymentsUpTo: 'Payments Up To',
    unknown: 'Unknown',
    appointmentType: 'Appointment Type',
    surgery: 'Surgery',
    undefined: 'Undefined',
    Scheduled: 'Scheduled',
    Confirmed: 'Confirmed',
    Done: 'Done',
    PreOp: 'PreOp',
    Checked: 'Checked',
    Cancelled: 'Cancelled',
    Rejected: 'Rejected',
    Surgery: 'Surgery',
    Unavailable: 'Unavailable',
    addToGiftCard: 'Add to credit balance',
    addToBalance: 'Add to balance',
    refundsCreatedFrom: 'Refunds Created From',
    refundsCreatedUpTo: 'Refunds Created Up To',
    lockPatient: 'Lock Patient',
    unlockPatient: 'Unlock Patient',
    lockedPatient: 'Locked Patient',
    chargeback: 'Chargeback',
    chargebacks: 'Chargebacks',
    unauthorizedChargebacksList: "You don't have authorization to view chargebacks list",
    addCategory: 'Add Category',
    addNewCategory: 'Add New Category',
    editNewCategory: 'Edit New Category',
    category: 'Category',
    spentMoneyByExternalProvider: 'Money Spent By External Providers',
    spentMoneyByExternalProvidersCategories: 'Money Spent By External Provider Categories',
    spentMoneyByExternalProviderDescription:
        'Generates a report of how much money was spent on external providers',
    spentMoneyByExternalProvidersCategoriesDescription:
        'Generates a report of how much money was spent in a external providers categories',
    anyExternalProvider: 'Any External Provider',
    anyExternalProviderCategory: 'Any Category',
    anyDepartment: 'Any Department',
};

export default messages;
